<template>
    <section class="bemvindo">
        <div class="container">
            <h1 class="bvtitle">Seja bem-vindo a plataforma <br> de <span>orçamento on-line</span> atlas.</h1>
            <div class="flex">

                <c-form-login title="SE VOCÊ JÁ POSSUI CADASTRO, REALIZE AQUI SEU ACESSO:"
                              button-label="Acessar"
                              button-class="send orange"
                              url="login"
                              ref="login"
                              :inputs="[
                                  {name: 'E-mail',    type: 'text',     placeholder: 'E-mail', required: 'required',  value: ''},
                              ]">
                </c-form-login>

                <div class="col-6">
                    <div class="item-login">
                        <div class="title">NÃO TENHO CADASTRO, QUERO ME CADASTRAR:</div>
                        <form @submit.prevent="goToRegister()">

                            <input type="text" placeholder="CNPJ" name="cnpj" v-model="register.cnpj" v-mask="'##.###.###/####-##'">
                            <input type="text" placeholder="CEP"  name="cep"  v-model="register.cep"  v-mask="'#####-###'">

                            <button class="send" type="submit" >Enviar</button>

                            <slot></slot>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import CFormLogin from "@/components/c-form-login";

    export default
    {
        name: 'p-login',
        components: {CFormLogin},
        data() {
            return {
                register: {
                    cnpj: '',
                    cep:  ''
                }
            }
        },
        methods: {
            goToRegister()
            {
                this.$emit('dataToRegister', this.register);
                this.$store.dispatch('modalInstructionsAlert/showModal')
                window.open('#register', "_self");
                
            }
        }
    }
</script>