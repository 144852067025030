<template>
    <section class="banner-a">
        <div class="container">
            <div class="info-container">
                <img src="@/assets/img/logo_meuspedidos.png" alt="atlas meus pedidos">
                <div class="headline">
                    Uma ferramenta <span>100% segura,</span><br>
                    ágil, prática e disponível<br>
                    <span>24hs por dia.</span>
                </div>
                <a href="#register">
                    <button class="btn btn-banner-a" @click="openInstructionsAlert">Quero me cadastrar</button>
                </a>
            </div>
        </div>
    </section>
</template>
<script>
export default {

    methods: {
        openInstructionsAlert() {
            this.$store.dispatch('modalInstructionsAlert/showModal')
        }
    }
}
</script>