<template>
    <section class="conheca">
        <div class="container">
            <div class="title">
                Conheça o Meus Pedidos
            </div>
            <p>
                Uma plataforma segura, onde você irá conhecer nosso mix completo de produtos, gerenciar seus pedidos, ter acesso a informações técnicas de produtos, dicas, vídeos, treinamentos e promoções, tudo em primeira mão.
            </p>
            <div class="flex">
                <div class="col">
                    <img src="@/assets/img/conheca_icone_01.png" alt="">
                    <span>
                        Catálogo de produtos com detalhes de fácil acesso
                    </span>
                </div>
                <div class="col">
                    <img src="@/assets/img/conheca_icone_02.png" alt="">
                    <span>
                        Seu pedidos 100% online sem necessidade de visita do representante
                    </span>
                </div>
                <div class="col">
                    <img src="@/assets/img/conheca_icone_03.png" alt="">
                    <span>
                        Agilidade e troca de informaçoes com o representante
                    </span>
                </div>
                <div class="col">
                    <img src="@/assets/img/conheca_icone_04.png" alt="">
                    <span>
                        Promoçoes e condiçoes de pagamentos exclusivas
                    </span>
                </div>
                <div class="col">
                    <img src="@/assets/img/conheca_icone_05.png" alt="">
                    <span>
                        Você interrompe e retorna de onde parou o orçamento quantas vezes quiser.
                    </span>
                </div>
            </div>

            <a href="#register" class="send orange" @click="openInstructionsAlert">Clique aqui e faça seu cadastro</a>
        </div>
    </section>
</template>

<script>
export default {

    methods: {
        openInstructionsAlert() {
            this.$store.dispatch('modalInstructionsAlert/showModal')
        }
    }
}
</script>