export function addGoogleTagManager() {
    let googleTagManagerScript = document.createElement('script')
    googleTagManagerScript.innerHTML = '(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({"gtm.start": new Date().getTime(),event:"gtm.js"});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!="dataLayer"?"&l="+l:"";j.async=true;j.src="https://www.googletagmanager.com/gtm.js?id="+i+dl;f.parentNode.insertBefore(j,f);})(window,document,"script","dataLayer","GTM-NHG4ZG6");'
    googleTagManagerScript.async = true
    document.head.appendChild(googleTagManagerScript)

    let googleTagManagerNoScript = document.createElement('noscript')
    let iframe = document.createElement('iframe')
    iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-NHG4ZG6"
    iframe.height = "0"
    iframe.width = "0"
    iframe.style.display = "none"
    iframe.style.visibility = "hidden"
    googleTagManagerNoScript.prepend(iframe)
    document.body.prepend(googleTagManagerNoScript)
    // <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NHG4ZG6" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
}

export function gtag(config) {
    let googleTagManagerScript = document.createElement('script')
    googleTagManagerScript.innerHTML = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${config}');`;
    document.head.appendChild(googleTagManagerScript)
}