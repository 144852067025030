<template>
    <section class="instructions">
        <div class="container">
            <c-instructions-video></c-instructions-video>
            <div class="btn-container">
                <a href="#register">
                    <button class="btn btn-instructions-b" @click="openInstructionsAlert">Quero me cadastrar</button>
                </a>
            </div>
        </div>
    </section>
</template>

<script>
    import CInstructionsVideo from "@/components/c-instructions-video";

    export default
    {
        name: 'v-instructions-video',
        components: {
            CInstructionsVideo
        },
        methods: {
            openInstructionsAlert() {
                this.$store.dispatch('modalInstructionsAlert/showModal')
            }
        }
    }
</script>