<template>
    <div class="modal-instruction-alert" v-if="modalInstructionsAlert.show">
        <div class="container">
            <div class="content">
                    <div class="headBox">
                        <h3>AVISO</h3>
                    </div>
                    <div class="contentBox">
                        <p>
                            Sujeito à aprovação do cadastro mediante visita do <br/> representante. Atendimento somente para varejos, <br/> lojas físicas e distribuidores.
                        </p>
                    </div>
                    <button class="btn btn-banner modal-button" @click="showModal">OK</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    
    export default {
        name: "c-instructions-alert",
        data() {
            return {}
        },       
        computed: mapState({
            modalInstructionsAlert: state => state.modalInstructionsAlert
        }),
        methods: { 	     
            showModal(e) {
                e.preventDefault();
                console.log('clicado');
                this.$store.dispatch('modalInstructionsAlert/showModal');
            },               
            confirmChoices() {   
                const checkedInputs = this.fields.filter(field => field.checked !== null);

                if (checkedInputs.length < 3)  {
                    this.message = 'Você deve selecionar todas as opções';
                    this.showMessage = true;

                    return false;
                }
            }
        }      
    }
</script>
