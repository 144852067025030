<template>
    <section class="banner-b">
        <div class="banner-b-container">
            <div class="headline">
                Cadastre-se agora e<br>
                inicie o seu orçamento
            </div>
            <a href="#register">
                <button class="btn btn-banner-b" @click="openInstructionsAlert">Quero me cadastrar</button>
            </a>
        </div>
    </section>
</template>


<script>
export default {

    methods: {
        openInstructionsAlert() {
            this.$store.dispatch('modalInstructionsAlert/showModal')
        }
    }
}
</script>