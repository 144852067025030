<template>
    <div class="landing-page-a">

        <c-header-logo></c-header-logo>

        <v-banner-a></v-banner-a>

        <v-gain-a :landing-page-key="landingPageKey" :register-data="registerData"></v-gain-a>

        <v-benefits-title></v-benefits-title>

        <v-benefits-icons></v-benefits-icons>

        <v-benefits-descriptions></v-benefits-descriptions>

        <v-instructions-video></v-instructions-video>

        <c-comments></c-comments>

        <v-footer-headline></v-footer-headline>

        <c-footer-logo></c-footer-logo>

        <c-footer-links></c-footer-links>

        <!-- <c-cookies-banner></c-cookies-banner>

        <c-modal-cookies-preferences></c-modal-cookies-preferences> -->

        <c-instructions-alert></c-instructions-alert>
    </div>
</template>

<script>
    import CHeaderLogo from "@/components/c-header-logo";
    import CFooterLogo from "@/components/c-footer-logo";
    import CFooterLinks from "@/components/c-footer-links";
    import CComments from "@/components/c-comments";
    import VBannerA from "./components/v-banner-a";
    import VGainA from "./components/v-gain-a";
    import VBenefitsTitle from "./components/v-benefits-title";
    // import CCookiesBanner from "@/components/c-cookies-banner";
    // import CModalCookiesPreferences from "@/components/c-modal-cookies-preferences";
    import VBenefitsIcons from "./components/v-benefits-icons";
    import VBenefitsDescriptions from "./components/v-benefits-descriptions";
    import VInstructionsVideo from "./components/v-instructions-video";
    import VFooterHeadline from "./components/v-footer-headline";
    import CInstructionsAlert from "@/components/c-instructions-alert";

    import { getCookiePolicy } from "@/utils/cookies" 

    import { addGoogleTagManager, gtag } from "@/scripts/google-tag-manager"


    
    export default
    {
        name: 'p-landing-page-a',
        components: {
            CHeaderLogo,
            CFooterLogo,
            CFooterLinks,
            CComments,
            VBannerA,
            VGainA,
            VBenefitsIcons,
            VBenefitsDescriptions,
            VBenefitsTitle,
            VInstructionsVideo,
            VFooterHeadline,
            // CCookiesBanner, 
            // CModalCookiesPreferences,
            CInstructionsAlert
        },
        data() {
      
            return {
                registerData: {
                    cep:  '',
                    cnpj: ''
                },
                landingPageKey: 'A'
            }
        },
        mounted() {
            if (getCookiePolicy({name: 'advertising_cookies'})) {
                gtag('UA-166109652-1')
                addGoogleTagManager()                
            }     
        },
        methods: {
            dataToRegister(value)
            {
                this.registerData = value;
            }
        }
    }
</script>