<template>
    <section class="know">
        <div class="container">
            <div class="know-icons-container">
                <div class="col-center">
                    <img class="know-icon" src="@/assets/img/conheca_icone_01.png" alt="">
                    <span>
                        Catálogo de produtos com detalhes de fácil acesso
                    </span>
                </div>
                <div class="col-center">
                    <img class="know-icon" src="@/assets/img/conheca_icone_02.png" alt="">
                    <span>
                        Seu pedidos 100% online sem necessidade de visita do representante
                    </span>
                </div>
                <div class="col-center">
                    <img class="know-icon" src="@/assets/img/conheca_icone_03.png" alt="">
                    <span>
                        Agilidade e troca de informaçoes com o representante
                    </span>
                </div>
                <div class="col-center">
                    <img class="know-icon" src="@/assets/img/conheca_icone_04.png" alt="">
                    <span>
                        Promoçoes e condiçoes de pagamentos exclusivas
                    </span>
                </div>
                <div class="col-center">
                    <img class="know-icon" src="@/assets/img/conheca_icone_05.png" alt="">
                    <span>
                        Você interrompe e retorna de onde parou o orçamento quantas vezes quiser.
                    </span>
                </div>
            </div>
            <c-register-form></c-register-form>
        </div>        
    </section>
</template>

<script>
    import CRegisterForm from "@/components/c-register-form";

    export default
    {
        name: 'v-know',
        components: {
            CRegisterForm
        }
    }
</script>