import Vue from 'vue'
import Vuex from 'vuex'
import modalCookiesPreferences from './modules/modal-cookies-preferences'
import modalInstructionsAlert from './modules/modal-instructions-alert'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        modalCookiesPreferences,
        modalInstructionsAlert
    },
})