<template>
    <div class="landing-page-b">

        <c-header-logo></c-header-logo>
        
        <c-banner></c-banner>
        
        <v-title></v-title>
        
        <v-instructions-video></v-instructions-video>
        
        <v-gain-b :landing-page-key="landingPageKey" :register-data="registerData"></v-gain-b>

        <v-benefits-title></v-benefits-title>
        
        <v-benefits-icons></v-benefits-icons>
        
        <v-benefits-descriptions></v-benefits-descriptions>

        <c-comments></c-comments>

        <v-banner-b></v-banner-b>

        <c-footer-logo></c-footer-logo>

        <c-footer-links></c-footer-links>

        <!-- <c-cookies-banner></c-cookies-banner>

        <c-modal-cookies-preferences></c-modal-cookies-preferences> -->
        
        <c-instructions-alert></c-instructions-alert>
    </div>
</template>

<script>
    import CHeaderLogo from "@/components/c-header-logo";
    import CFooterLogo from "@/components/c-footer-logo";
    import CFooterLinks from "@/components/c-footer-links";
    import CBanner from "@/components/c-banner";
    import CComments from "@/components/c-comments";
    import VTitle from "./components/v-title";
    import VGainB from "./components/v-gain-b";
    import VInstructionsVideo from "./components/v-instructions-video";
    import VBenefitsTitle from "./components/v-benefits-title";
    import VBenefitsIcons from "./components/v-benefits-icons";
    import VBenefitsDescriptions from "./components/v-benefits-descriptions";
    import VBannerB from "./components/v-banner-b";
    // import CCookiesBanner from "@/components/c-cookies-banner";
    // import CModalCookiesPreferences from "@/components/c-modal-cookies-preferences";
    import CInstructionsAlert from '../../components/c-instructions-alert';

    import { getCookiePolicy } from "@/utils/cookies"

    import { addGoogleTagManager, gtag } from "@/scripts/google-tag-manager"


    export default
    {
        name: 'p-landing-page-b',
        components: {
            CBanner,
            CHeaderLogo,
            CFooterLogo,
            CFooterLinks,
            CComments,
            VTitle,
            VGainB,
            VInstructionsVideo,
            VBenefitsTitle,
            VBenefitsIcons,
            VBenefitsDescriptions,
            VBannerB,
            // CCookiesBanner, 
            // CModalCookiesPreferences,
            CInstructionsAlert
        },
        data() {
            return {
                registerData: {
                    cep:  '',
                    cnpj: ''
                },
                landingPageKey: 'B'
            }
        },
        mounted() {
            if (getCookiePolicy({name: 'advertising_cookies'})) {
                gtag('UA-166109652-1')
                addGoogleTagManager()                
            }
        },
        methods: {
            dataToRegister(value)
            {
                this.registerData = value;
            }
        }
    }
</script>