<template>
    <div class="term-and-conditions">
        <c-header-policy></c-header-policy>

        <div class="container">
            <h2 class="title">Termos e Condições de Uso</h2>

            <div class="box-content">                
                <p class="topic"><strong>TERMOS E CONDIÇÕES GERAIS DE USO DO “MEUS PEDIDOS ATLAS”.</strong></p>
                
                <p>
                    Estes Termos e Condições Gerais de Uso aplicam-se a plataforma de comunicação oferecida pela ATLAS S/A., pessoa jurídica de direito privado, 
                    inscrita no CNPJ sob nº. 89.723.837/0001-72, com sede no Km 258, da Rodovia BR 116, na cidade de Esteio/RS.
                </p>

                <p>
                    A plataforma é destinada unicamente e tão somente para as <strong>pessoas jurídicas </strong>com as quais a Atlas S/A mantém uma relação 
                    comercial, doravante denominada de <strong>Usuários.</strong>
                </p>

                <p>
                    Os <strong>Usuários</strong> deverão ler e se certificar de haver entendido e aceitado todas as condições estabelecidas nos Termos e Condições 
                    Gerais de Uso e nas Políticas de Privacidade, assim como nos demais documentos incorporados aos mesmos por referência, antes de seu cadastro 
                    como <strong>Usuários</strong> na plataforma “Meus Pedidos Atlas”, para poderem ter acesso aos serviços e funcionalidades disponibilizadas 
                    pela ferramenta. Em caso de dúvidas, entre em contato através do e-mail: <a href="mailto:contato.portal@pinceisatlas.com.br">contato.portal@pinceisatlas.com.br</a>.
                </p>

                <p>&nbsp;</p><p>&nbsp;</p>

                <p class="topic"><strong>DEFINIÇÕES:</strong></p>

                <p><strong>Usuário</strong><strong>:</strong> Pessoas jurídicas que já são clientes cadastrados na empresa Atlas S/A.</p>

                <p>
                    <strong>Área pessoal restrita</strong><strong>:</strong> Seção da plataforma acessada pelo <strong>Usuário</strong> mediante o fornecimento de login e senha, tendo 
                    acesso às informações e ferramentas disponibilizadas pela empresa Atlas S/A. no ambiente virtual.
                </p>

                <p>
                    <strong>Login</strong><strong>:</strong> Identificado e escolhido pelo <strong>Usuário</strong> quando preenche o cadastro para acesso à área pessoal 
                    restrita e as funcionalidades exclusivas da plataforma “Meus Pedidos Atlas”.</p>

                <p><strong>Senha</strong><strong>:</strong> Conjunto de caracteres que serve como prova de identidade do <strong>Usuário</strong>, cujo conhecimento deve ser exclusivo e único.</p>

                <p>
                    <strong>Dados Cadastrais</strong><strong>:</strong> Conjunto de informações pessoais de um <strong>Usuário</strong> de modo que o identifique, 
                    a exemplo do número do cadastro de pessoa jurídica (CNPJ), endereço comercial, nome completo, dentre outros.
                </p>

                <p>&nbsp;</p><p>&nbsp;</p>

                <p class="topic"><strong>OBJETIVO: </strong></p>

                <p>
                    A Plataforma “Meus Pedidos Atlas<strong>”</strong> consiste em um canal de comunicação entre as empresas de Representação Comercial que mantém uma 
                    relação contratual com a Atlas S/A. e os <strong>Usuários </strong>(clientes), em que estes poderão ter acesso a catálogos de produtos com fotos, fichas técnicas, 
                    referências e demais informações para possibilitar que realizem solicitação de orçamentos on-line, selecionando produtos (quantidades desejadas).
                </p>

                <p>&nbsp;</p><p>&nbsp;</p>

                <p class="topic"><strong>1. DESCRIÇÃO DOS PROCEDIMENTOS REFERENTE AO OBJETIVO DA PLATAFORMA</strong>:</p>

                <p>1.1 O <strong>Usuário</strong> poderá navegar na plataforma e selecionar produtos para realizar uma cotação/orçamento na forma <em>on-line</em>;</p>

                <p>1.2 A relação de produtos escolhidos será enviada para a empresa de representação comercial que lhe atende, através do Carrinho de Compras na plataforma;</p>

                <p>
                    1.3 Ao receber a cotação o representante comercial irá observar a disponibilidade dos produtos, preços e condições de pagamento e após enviará um 
                    orçamento detalhado ao <strong>Usuário</strong> para aprovação;
                </p>

                <p>1.4 Caso aprovado, o representante comercial enviará o pedido para a Atlas S/A. e constará na plataforma a informação “Executado”;</p>

                <p>1.5 Na plataforma “Meus Pedidos Atlas” estão disponíveis vídeos que explicam didaticamente o processo desde a seleção dos produtos até a finalização do pedido, com a posterior aprovação;</p>

                <p>
                    1.6 Os preços repassados pelo representante ao <strong>Usuário</strong> são de inteira responsabilidade da empresa de representação comercial. 
                    Em caso de desrespeito à política comercial, como valores e prazos equivocados, o prejuízo será arcado pela a empresa de representação comercial.
                </p>

                <p>
                    1.7 O serviço disponibilizado pela plataforma “Meus Pedidos Atlas” é gratuito, não havendo cobrança dos <strong>Usuários </strong>para que 
                    utilizem esse serviço on-line para cotação de produtos.
                </p>

                <p>&nbsp;</p><p>&nbsp;</p>

                <p class="topic"><strong>2. CADASTRO:</strong></p>

                <p>2.1 O acesso a Plataforma “Meus Pedidos Atlas” somente se dará mediante a realização de um procedimento de cadastramento por parte do <strong>Usuário;</strong></p>

                <ol>
                    <li>
                        <ol>
                            <li>As Pessoas Jurídicas poderão cadastrar somente um colaborador para acessar a plataforma.</li>
                        </ol>
                    </li>
                </ol>

                <p>
                    2.3 Durante o processo de cadastramento, o <strong>Usuário</strong> deverá criar uma senha pessoal, intransferível e confidencial. A senha segura deverá 
                    conter no mínimo 6 (seis) caracteres, incluindo letras e números e ser de difícil adivinhação, não sendo nomes de entes próximos e/ou datas especiais;
                </p>

                <p>
                    2.4 O <strong>Usuário</strong> acessará sua conta através de <em>login</em> (apelido) e senha, e se compromete zelar pela segurança de sua senha, 
                    devendo alterá-la imediatamente caso tome conhecimento ou desconfie de que a mesma tenha sido descoberta por terceiro;
                </p>

                <p>
                    2.5 Ao efetuar o cadastramento, o <strong>Usuário</strong> se compromete a fornecer informações verdadeiras e atualizadas sobre sua pessoa e a empresa que 
                    representa.&nbsp; Não podem realizar o cadastro pessoas que não ostentem a condição de representantes das pessoas jurídicas clientes da Atlas S/A.
                </p>

                <p>
                    2.6 A plataforma “Meus Pedidos Atlas”<strong> </strong>não se responsabilizará pelos dados pessoais inseridos por seus <strong>Usuários</strong>;
                </p>

                <p>
                    2.7 Durante o cadastramento o Usuário manifestará seu expresso consentimento com os Termos de Uso da plataforma “Meus Pedidos Atlas”, mediante um 
                    procedimento de validação eletrônica cujo registro será armazenado de forma segura;
                </p>

                <p>
                    2.8 O <strong>Usuário</strong> receberá, em sua caixa de e-mail cadastrada, uma mensagem referente à confirmação de seu cadastro na plataforma 
                    “Meus Pedidos Atlas”, contendo um link de ativação.<strong> </strong>Caso o <strong>Usuário</strong> não concorde com os Termos de Uso da 
                    plataforma “Meus Pedidos Atlas”, não será possível acessar navegação exclusiva;
                </p>

                <p>2.9 Em caso de esquecimento da senha pelo <strong>Usuário</strong>, ele<strong> </strong>poderá requerer o envio de sua senha para seu e-mail cadastrado, no item “Esqueci Minha Senha”;</p>

                <p>2.10 O<strong> </strong><strong>Usuário</strong> poderá visualizar e alterar seus dados pessoais no cadastrado, posteriormente, a qualquer momento, mediante acesso à sua área pessoal restrita;</p>

                <p>
                    2.11 A plataforma se reserva o direito de utilizar todos os meios válidos e possíveis para identificar os <strong>Usuários</strong>, 
                    bem como de solicitar dados adicionais e documentos que estime serem pertinentes, a fim de conferir os dados pessoais ou jurídicos informados. Caso seja constatada a 
                    utilização de dados incorretos ou inverídicos, ou ainda, caso o <strong>Usuário </strong>se negue a enviar os documentos requeridos, a Atlas S/A. poderá bloquear, suspender 
                    temporariamente ou cancelar definitivamente o cadastro, sem prejuízo de outras medidas que entender necessárias e oportunas;
                </p>

                <p>2.12 O <em>login</em> que o <strong>Usuário</strong> irá utilizar na plataforma não poderá guardar semelhança com o nome ATLAS. Também serão eliminados apelidos considerados ofensivos;</p>

                <p>
                    2.13 A plataforma “Meus Pedidos Atlas” se reserva o direito de recusar qualquer solicitação de cadastro e de cancelar um cadastro previamente aceito, 
                    desde que em desacordo com as políticas e regras do presente termo;
                </p>

                <p>2.14 Eventuais casos de apuração de responsabilidades cíveis e criminais do <strong>Usuário</strong>, infratores destes Termos de Uso serão tratados de forma independente.</p>

                <p>&nbsp;</p><p>&nbsp;</p>

                <p class="topic"><strong>3. DEVERES DO USUÁRIO:</strong></p>

                <p>
                    <strong>3.1 </strong>O <strong>Usuário</strong> assume todo o ônus e responsabilidade decorrentes de seus atos na plataforma “Meus Pedidos Atlas”, respondendo, ainda, pelos atos que 
                    terceiros praticarem em seu nome, por meio do uso de seu login e senha;
                </p>

                <p>
                    3.2 O <strong>Usuário </strong>se compromete a notificar a plataforma, imediatamente, através do e-mail <a href="mailto:contato.portal@pinceisatlas.com.br">contato.portal@pinceisatlas.com.br</a> 
                    a respeito de qualquer uso não autorizado de sua conta, bem como o acesso não autorizado por terceiros a mesma;
                </p>

                <p>
                    3.3 O <strong>Usuário</strong> é o único responsável pelas operações efetuadas em sua conta, uma vez que o acesso à mesma só será possível mediante a aposição da senha, cujo 
                    conhecimento é exclusivo dele;
                </p>

                <p>
                    3.4 O <strong>Usuário</strong> deverá indenizar a plataforma “Meus Pedidos Atlas” por quaisquer danos e prejuízos decorrentes de suas ações ou omissões que violem as disposições 
                    contidas nestes Termos de Uso ou as normas legais em vigor.
                </p>

                <p>&nbsp;</p><p>&nbsp;</p>

                <p class="topic"><strong>4. DEVERES DA EMPRESA DE REPRESENTAÇÃO COMERCIAL</strong>:</p>

                <p>
                    4.1 O representante comercial será responsável pela entrega do orçamento ao <strong>Usuário</strong>, sendo que suas obrigações perante a Atlas S/A. 
                    estão previstas em outro regramento incluído em outra plataforma, para uso exclusivo de empresas representantes comerciais;
                </p>

                <p>
                    4.2 Caso venha a ser encerrada a relação contratual entre a empresa de Representação Comercial e a Atlas S/A. e ainda tiver orçamentos em andamento, 
                    não disponibilizados para o <strong>Usuário</strong>, a Atlas S/A ficará responsável pela entrega do orçamento, nos moldes anteriormente.
                </p>

                <p>&nbsp;</p><p>&nbsp;</p>

                <p class="topic"><strong>5. LIMITAÇÃO DE RESPONSABILIDADE:</strong></p>

                <p>5.1 Em decorrência de questões operacionais, a plataforma está sujeita a eventuais problemas de interrupção, falha técnica e indisponibilidade de funcionamento temporário.</p>

                <p>
                    5.2 A Atlas S/A. emprega esforços para garantir a segurança e confiabilidade da plataforma Meus Pedidos Atlas e dos conteúdos disponibilizados no mesmo, para isso emprega 
                    diversos controles de detecção, prevenção e combate a atividades maliciosas. Portanto, a Atlas S/A. é responsável pela segurança virtual da plataforma. No entanto, não é 
                    responsável por quaisquer danos e prejuízos provenientes da conduta inadequada do <strong>Usuário</strong> no ambiente virtual e/ou que de qualquer forma fujam de sua esfera de vigilância;
                </p>

                <p>
                    5.3 A Atlas S/A. não detém qualquer responsabilidade pela navegação do <strong>Usuário</strong> pela internet em sites externos, mesmo quando referenciados 
                    pela plataforma “Meus Pedidos Atlas”, sendo um dever do <strong>Usuário</strong> a leitura dos Termos de Uso e Política de Privacidade de qualquer site acessado.
                </p>

                <p>&nbsp;</p><p>&nbsp;</p>

                <p class="topic"><strong>6. EXCLUSÃO DO USUÁRIO DA PLATAFORMA:</strong></p>

                <p>
                    6.1 O <strong>Usuário</strong> tem acesso à plataforma “Meus Pedidos Atlas” enquanto perdurar a relação contratual com a empresa Atlas S/A e o CNPJ do <strong>Usuário </strong>estiver ativo. 
                    Uma vez encerrada essa relação ou o CNPJ estiver inativo junto aos órgãos legais, automaticamente, é cancelado o acesso do <strong>Usuário</strong> a “Meus Pedidos Atlas”;
                </p>

                <p>6.2 O <strong>Usuário</strong> que desejar, poderá realizar o descadastramento junto à plataforma “Meus Pedidos Atlas”;</p>

                <p>
                    6.3 A Atlas S/A. poderá a qualquer momento encerrar o acesso do <strong>Usuário</strong> à plataforma, se comprovar que: ocorreu violação de qualquer cláusula deste Termo 
                    (ou tiver agido de forma que demonstre a intenção de não cumprir as cláusulas deste instrumento), quando o <strong>Usuário</strong> não tiver mais relação comercial (descadastrado) 
                    com a Atlas S/A.
                </p>

                <p>&nbsp;</p><p>&nbsp;</p>

                <p class="topic"><strong>7. NAVEGAÇÃO</strong>:</p>

                <p>
                    7.1 A Plataforma “Meus Pedidos Atlas” é melhor visualizada com uma resolução mínima de 1366x768 <em>pixels</em> e é compatível com os navegadores: <em>Chrome</em> 80; <em>Mozilla 
                    Firefox74, Safari, e Microsoft Edge</em> 80 ou superiores;
                </p>

                <p>
                    7.2 Para visualizar alguns conteúdos disponíveis no <em>site</em> “Meus Pedidos Atlas” é necessário a instalação do aplicativo <em>Adobe Acrobat Reader</em> ou outro 
                    leitor de PDF de sua preferência, além de recursos de <em>Java script</em> e <em>cookies</em> habilitados no <em>browser</em>;
                </p>

                <p>
                    7.3 O <strong>Usuário </strong>é responsável por realizar a instalação/habilitação de tais recursos em seus equipamentos de informática, assim como por mantê-lo seguro, 
                    através da utilização de ferramentas de segurança lógica, tais como antivírus e <em>firewall</em> pessoal;
                </p>

                <p>
                    7.4 Salvo disposição diversa ou notificação, a plataforma “Meus Pedidos Atlas” procura manter todo o conteúdo disponível 24 (vinte e quatro) horas por dia, 07 (sete) dias 
                    por semana, com base no horário oficial de Brasília.
                </p>

                <p>
                    7.5 Devido às características inerentes ao ambiente da Internet, a Atlas S/A. não tem como garantir que o acesso ao website esteja livre de 
                    problemas ocasionados por casos fortuitos, internos ou externos, casos de força maior ou por outros casos não inteiramente sujeitos a controle direto pela Atlas S/A;
                </p>

                <p>
                    7.6 Eventualmente a plataforma poderá não estar disponível devido a várias situações, tais como: manutenções técnicas e/ ou operacionais que exijam a retirada do site do ar ou impossibilitem o seu acesso;<strong> </strong>casos fortuitos ou força maior,<br>
                    ações de terceiros que impeçam a disponibilização do site,<br>
                    interrupção ou suspensão dos serviços prestados pelas prestadoras de serviços de telecomunicações e ocorrências de falhas na transmissão e/ou roteamento no acesso à Internet, entre outros.
                </p>

                <p>&nbsp;</p><p>&nbsp;</p>

                <p class="topic"><strong>8. PROPRIEDADE INTELECTUAL:</strong></p>

                <p>
                    8.1 Os conteúdos da plataforma, incluindo, mas não se limitando a, quaisquer programas, dados e arquivos, assim como o uso da expressão “Meus Pedidos Atlas” como 
                    marca e nome de domínio são de propriedade exclusiva da Atlas S/A. ou, eventualmente, estão adequadamente cobertas por licença/cessão de uso;
                </p>

                <p>
                    8.2 O simples acesso a plataforma “Meus Pedidos Atlas” não confere ao <strong>Usuário</strong> qualquer direito de uso sobre os conteúdos compreendidos como todo ou 
                    fração, que nele estejam, estiveram ou venham a estar disponíveis. Portanto, os conteúdos exibidos na plataforma “Meus Pedidos Atlas” não poderão, de qualquer forma, serem copiados, 
                    distribuídos, reproduzidos ou divulgados, em qualquer meio, sob pena de responsabilidade civil e/ou criminal;
                </p>

                <p>
                    8.3 Autorizações para uso envolvendo a cópia dos conteúdos disponíveis da plataforma “Meus Pedidos Atlas” poderão ser obtidas mediante solicitação prévia e 
                    autorização formal, desde que as finalidades sejam estritamente pessoais, não comerciais e não envolvam modificação dos conteúdos;
                </p>

                <p>8.4 As imagens utilizadas na plataforma “Meus Pedidos Atlas” podem não refletir o tamanho original ou situação atual do conteúdo reproduzido, sendo meramente ilustrativos.</p>

                <p>&nbsp;</p><p>&nbsp;</p>

                <p class="topic"><strong>DISPOSIÇÕES FINAIS.</strong></p>

                <p>A disponibilização dos serviços referidos nesta plataforma fica condicionada à observância das condições preestabelecidas neste documento e a regulamentação jurídica aplicável.</p>

                <p>A tolerância por eventual descumprimento de quaisquer dos itens do presente instrumento não constituirá novação e tampouco impedirá ou inibirá a exigibilidade dos mesmos a qualquer tempo.</p>

                <p>
                    A Atlas S/A. como proprietária da plataforma se reserva no direito de modificar, corrigir ou fazer qualquer outra alteração no website, sem aviso prévio. O texto 
                    alterado será publicado na plataforma “Meus Pedidos Atlas” com a sinalização para que todos que a acessem tenham o conhecimento das alterações. Caso não haja concordância com as 
                    alterações, ou fique qualquer dúvida, o <strong>Usuário </strong>poderá contatar a Central de Atendimento da Atlas S/A. pelo e-mail 
                    <a href="mailto:contato.portal@pinceisatlas.com.br">contato.portal@pinceisatlas.com.br</a>
                </p>

                <p>
                    As determinações constantes no Termo e Condições de Uso desta plataforma estão de acordo com as legislações vigentes no Brasil, que incidem nesse modelo de negócio, 
                    ficando o Foro da Comarca de Esteio, no Estado do Rio Grande do Sul, com expressa renúncia de qualquer outro, por mais privilegiado que seja, para dirimir qualquer dúvida, 
                    pendência ou litígio oriundo destes Termos de Uso.
                </p>

                <p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p>

                <p>Termos e Condições de Uso atualizado em 15 de abril de 2020.</p>

            </div>
        </div>

        <c-footer-policy></c-footer-policy>

        <div class="container">
            <c-footer-links></c-footer-links>
        </div>

        <!-- <c-cookies-banner></c-cookies-banner>

        <c-modal-cookies-preferences></c-modal-cookies-preferences> -->
    </div>
</template>

<script>
    import CHeaderPolicy from "@/components/c-header-policy";
    import CFooterPolicy from "@/components/c-footer-policy";
    // import CCookiesBanner from "@/components/c-cookies-banner";
    // import CModalCookiesPreferences from "@/components/c-modal-cookies-preferences";
    import CFooterLinks from "@/components/c-footer-links";

    export default
    {
        name: 'p-term-and-conditions',        
        components: {
            CHeaderPolicy,
            CFooterPolicy,
            // CCookiesBanner,
            // CModalCookiesPreferences,
            CFooterLinks
        },    
    }
</script>