<template>
    <div class="col-6">
        <div class="item-login">
            <div class="title">{{title}}</div>
            <form @submit.prevent="sendData()">

                <input v-for="input in inputs"
                       :type="input.type"
                       :placeholder="input.placeholder"
                       v-model="input.value"
                       :required="required"
                       v-bind:key="input.name">

                <button :class="buttonClass" type="submit">{{buttonLabel}}</button>

                <span class="msg success" v-if="success">{{message}}</span>

                <span class="msg error" v-if="error">{{message}}</span>

                <slot></slot>
            </form>
        </div>
      <vue-element-loading :active="loading" is-full-screen />
    </div>
</template>

<script>
    const URL = process.env.VUE_APP_API_URL;

    export default {
        name: "c-form-login",
        props : {
            title: String,
            buttonLabel: String,
            buttonClass: String,
            inputs: Array,
            url: String,
            required: String
        },
        data() {
            return {
                error: false,
                success: false,
                message: '',
                loading: false
            }
        },
        methods: {
            sendData()
            {
                const EMAIL    = this.inputs[0].value;

                if (EMAIL)
                {
                    this.loading = true;
                    this.resetMessage();
                    this.axios.post(URL + '/my-orders/customer-auth/get-login-url', {email: EMAIL})
                    .then(({data}) => {
                        if (data.data.login_url)
                        {
                           window.open(data.data.login_url, "_self");
                        }
                        else
                        {
                            this.loading = false;

                            switch (data.message) {
                               case 'representative_without_site':
                                   this.showMessage('Site indisponível para este usuário. Contate contato.portal@pinceisatlas.com.br.');
                                   break;
                                case 'login_attempts':
                                   this.showMessage('Muitas tentativas de login realizadas, por favor tente novamente em 5 minutos.');
                                   break;
                               default:
                                   this.showMessage('Credenciais inválidas');
                                   break;
                            }
                        }
                    })
                    .catch(() => {
                        this.loading = false;
                        this.showMessage('Não foi possível checar as credenciais');
                    })
                }
                else
                {
                    this.showMessage("Informe as credenciais!");
                }
            },
            showMessage(message, type = 'error')
            {
                this.resetMessage();

                if (type === 'success')
                {
                    this.success = true;
                }
                else
                {
                    this.error = true;
                }

                this.message = message;
            },
            resetMessage()
            {
                this.error   = false;
                this.success = false;
                this.message = '';
            }
        }
    }
</script>
