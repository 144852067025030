import Home from '../views/home/p-home';
import LandingPageA from '../views/landing-page-a/p-landing-page-a';
import LandingPageB from '../views/landing-page-b/p-landing-page-b';
import LandingPageC from '../views/landing-page-c/p-landing-page-c';
import ThankYou from '../views/thank-you/p-thank-you';
import TermAndConditions from '../views/term-and-conditions/p-term-and-conditions';
import PrivacyPolicy from '../views/privacy-policy/p-privacy-policy';

const _ROOT = process.env.VUE_APP_ROUTES_ROOT;

export default [
    {
        path: _ROOT,
        name: 'home',
        component: Home
    },
    {
        path: _ROOT + 'pedidos_a',
        name: 'landing-page-a',
        component: LandingPageA
    },
    {
        path: _ROOT + 'pedidos_b',
        name: 'landing-page-b',
        component: LandingPageB
    },
    {
        path: _ROOT + 'pedidos_c',
        name: 'landing-page-c',
        component: LandingPageC
    },
    {
        path: _ROOT + 'pedidos_a/obrigado',
        name: 'page-a-thanks',
        component: ThankYou
    },
    {
        path: _ROOT + 'pedidos_b/obrigado',
        name: 'page-b-thanks',
        component: ThankYou
    },
    {
        path: _ROOT + 'termos-e-condicoes-de-uso',
        name: 'page-term-and-conditions',
        component: TermAndConditions
    },
    {
        path: _ROOT + 'politica-privacidade',
        name: 'page-privacy-policy',
        component: PrivacyPolicy
    },
    {
        path: _ROOT + '*',
        redirect: _ROOT
    }
]