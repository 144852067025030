<template>
    <section class="banner">
        <div class="container">
            <img src="@/assets/img/banner_topo.jpg" alt="Meus pedidos">
            <div class="logo">
                <h1>
                    
                </h1>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "c-banner"
    }
</script>
