import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import VueAxios from 'vue-axios';
import axios from 'axios';
import Toasted from 'vue-toasted';
import VueMask from 'v-mask';
import VueCookies from 'vue-cookies';

import VueElementLoading from 'vue-element-loading';

import rDefault from './router/r-default';

import store from './store'

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(VueAxios, axios);
Vue.use(Toasted);
Vue.use(VueCookies);
Vue.use(VueMask);

Vue.component('VueElementLoading', VueElementLoading);

const router = new VueRouter({
  routes: [
    ...rDefault
  ],
  mode: 'history'
});

new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app');