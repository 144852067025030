<template>
    <router-view></router-view>
</template>

<style lang="scss">
  @import "./assets/sass/app";
</style>

<script>
  export default {
    name: 'App',
  }
</script>